import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class Mobilizations extends React.Component {
  render() {
    return (
      <div class="infoBox">
      <h1 className="practiceTitle">Mobilizations</h1>

      </div>
    );
  }
}
