import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./App.css";
import Desktop from "./srcDesktop/Index.js";


class App extends Component {
  render() {
    return (
      <div id="App">
          <Desktop />
      </div>
    );
  }
}

export default App;
